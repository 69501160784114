const AboutText = () => {
  return (
    <div className="about__inside-value">
      Мы специализируемся на оптовых поставках нефтепродуктов автомобильным,
      железнодорожным и речным транспортом, а также самовывозом с
      нефтеперерабатывающих заводов и складов ГСМ, оказываем услуги по перевозке
      нефтепродуктов автотранспортом, перевалке и хранению нефтепродуктов на
      собственном складе ГСМ. <br />
      <br />
      Мы точно найдем решение комплексных логистических задач!
    </div>
  );
};

export default AboutText;
