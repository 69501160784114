import React from "react";
import cn from "classnames";

const Logo = ({ row, bgColor = "#fff", className = "" }) => {
  return (
    <div className={cn("logo", className, { flex: row })}>
      <div className="logo__icon">
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.71448 2.28299H33.4997V32.7719H24.6411V35.055H33.4997H35.805V32.7719V2.28299V-0.00012207H33.4997H2.71448H0.40918V2.28299V22.7884H2.71448V2.28299Z"
            fill={bgColor}
          />
          <path
            d="M10.6451 28.4638C10.6451 29.2757 10.3838 29.9403 9.86125 30.4578C9.34892 30.9753 8.61637 31.3001 7.66342 31.432L10.599 34.7046V35.0547H7.95544L4.82017 31.4777H2.71456V35.0547H0.40918V25.0695H6.80279C8.0016 25.0695 8.93913 25.3587 9.61531 25.9371C10.3019 26.5053 10.6451 27.2918 10.6451 28.2964V28.4638ZM8.29362 28.2508C8.29362 27.8753 8.16043 27.5912 7.89404 27.3984C7.63788 27.1954 7.25363 27.094 6.74131 27.094H2.71456V29.5902H6.74131C7.24333 29.5902 7.62757 29.4938 7.89404 29.301C8.16043 29.0981 8.29362 28.8038 8.29362 28.4182V28.2508ZM17.5561 35.2374C15.8962 35.2374 14.636 34.9431 13.7752 34.3545C12.9146 33.7659 12.4842 32.9034 12.4842 31.7669V31.6146H14.7588V31.7669C14.7588 32.254 15.0048 32.6244 15.4966 32.8781C15.9884 33.1317 16.7056 33.2585 17.6483 33.2585C18.4885 33.2585 19.1238 33.1723 19.5541 32.9998C19.9947 32.8172 20.2149 32.5533 20.2149 32.2083V32.1017C20.2149 31.8278 20.092 31.6197 19.8461 31.4777C19.6001 31.3254 19.1647 31.2037 18.5397 31.1124L15.9731 30.747C13.7599 30.4223 12.6533 29.5192 12.6533 28.0377V27.8854C12.6533 26.9417 13.0682 26.206 13.8982 25.6784C14.7384 25.1507 15.9116 24.8868 17.4177 24.8868C19.0161 24.8868 20.2354 25.171 21.0756 25.7393C21.9158 26.3075 22.3359 27.1295 22.3359 28.2051V28.3421H20.0613V28.2051C20.0613 27.3121 19.1801 26.8657 17.4177 26.8657C16.6288 26.8657 16.0242 26.9468 15.6042 27.1091C15.1944 27.2715 14.9894 27.51 14.9894 27.8245V27.9463C14.9894 28.1797 15.1021 28.3624 15.3275 28.4943C15.5632 28.6262 15.9628 28.7328 16.5264 28.8139L19.1083 29.1793C20.2867 29.3518 21.1525 29.6664 21.7057 30.123C22.2693 30.5695 22.5511 31.1935 22.5511 31.9952V32.1474C22.5511 33.1216 22.1156 33.8827 21.2447 34.4306C20.3737 34.9684 19.1442 35.2374 17.5561 35.2374Z"
            fill={bgColor}
          />
        </svg>
      </div>
      <div className="logo__bottom">
        <div className="logo_title">Регионснаб</div>
        <div className="logo_subtitle">поставки нефтепродуктов</div>
      </div>
    </div>
  );
};

export default Logo;
