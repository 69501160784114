import React from "react";

const CallBtn = () => {
  return (
    <a href="tel:+73912191597" className="callBtn">
      <svg
        width="26"
        height="27"
        viewBox="0 0 26 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 11.4663H17.875C17.8744 10.82 17.6173 10.2004 17.1604 9.74345C16.7034 9.28647 16.0838 9.02945 15.4375 9.02881V7.40381C16.5145 7.4051 17.5471 7.83353 18.3087 8.59511C19.0703 9.3567 19.4987 10.3893 19.5 11.4663Z"
          fill="white"
        />
        <path
          d="M22.7498 11.4663H21.1248C21.1231 9.95842 20.5234 8.51278 19.4571 7.44654C18.3909 6.3803 16.9452 5.78053 15.4373 5.77881V4.15381C17.3761 4.15596 19.2348 4.92707 20.6057 6.29797C21.9766 7.66886 22.7477 9.52757 22.7498 11.4663ZM21.1248 24.4663H20.9867C5.0211 23.5482 2.75422 10.0769 2.43735 5.96568C2.38782 5.32117 2.59634 4.68337 3.01703 4.19258C3.43773 3.7018 4.03615 3.39822 4.68066 3.34862C4.74512 3.34375 4.80985 3.34131 4.87485 3.34131H9.15672C9.48217 3.34099 9.80022 3.43841 10.0697 3.62094C10.3391 3.80347 10.5476 4.0627 10.668 4.36506L11.903 7.40381C12.0219 7.69919 12.0514 8.023 11.9878 8.33501C11.9243 8.64702 11.7705 8.93348 11.5455 9.15881L9.81485 10.9057C10.0841 12.4424 10.8194 13.8591 11.9211 14.9637C13.0228 16.0683 14.4376 16.8074 15.9736 17.0807L17.7367 15.3338C17.9654 15.1113 18.2546 14.9611 18.5681 14.9018C18.8817 14.8426 19.2057 14.877 19.4998 15.0007L22.563 16.2276C22.8608 16.3518 23.1148 16.5618 23.2928 16.831C23.4708 17.1001 23.5646 17.4162 23.5623 17.7388V22.0288C23.5623 22.6753 23.3055 23.2953 22.8484 23.7524C22.3913 24.2095 21.7713 24.4663 21.1248 24.4663ZM4.87485 4.96631C4.76815 4.96599 4.66243 4.98669 4.56373 5.02722C4.46503 5.06776 4.37528 5.12734 4.29961 5.20256C4.22393 5.27778 4.16382 5.36717 4.12269 5.46563C4.08156 5.56408 4.06023 5.66967 4.05991 5.77637C4.05991 5.79912 4.06072 5.8216 4.06235 5.84381C4.4361 10.6538 6.83297 22.0288 21.0761 22.8413C21.2911 22.8542 21.5025 22.7811 21.6637 22.6382C21.8249 22.4953 21.9228 22.2942 21.9357 22.0792L21.9373 22.0288V17.7388L18.8742 16.5119L16.5423 18.8276L16.1523 18.7788C9.0836 17.8932 8.12485 10.8244 8.12485 10.7513L8.0761 10.3613L10.3836 8.02943L9.16485 4.96631H4.87485Z"
          fill="white"
        />
      </svg>
    </a>
  );
};

export default CallBtn;
