import React from "react";
import Header from "@components/Header/Header";
const AboutIntro = () => {
  return (
    <section id="aboutIntro">
      <Header />
      <div className="container">
        <div className="aboutIntro__inside-title">О компании</div>
        <div className="aboutIntro__inside">
          <div className="colum">
            <div className="aboutIntro__inside-text">
              <div className="aboutIntro__inside-text-prev">
                <span>РегионСнаб</span> – амбициозная, динамично развивающаяся
                компания на рынке нефтепродуктов, входящая в «ТОП 100» компаний
                Красноярского края, которая обладает огромным опытом оптовой
                реализации нефтепродуктов.
              </div>
              Компания зарекомендовала себя как надежный, стабильный и
              профессиональный партнер, обладающий всей необходимой
              инфраструктурой и финансовыми средствами для выполнения своих
              обязательств и полного соответствия ожиданиям потребителей.
            </div>
          </div>
          <div className="colum">
            <div className="aboutIntro__inside-text">
              ООО «РегионСнаб» на протяжении 11-ти лет успешно справляется с
              задачами безаварийной и своевременной поставки и транспортировки
              нефтепродуктов в Красноярском крае и близлежащих регионах. Мы
              имеем прямые договора поставки нефтепродуктов и оказания
              транспортных услуг с крупнейшими сетями АЗС федерального и
              регионального значения, а также с большим количеством частных
              владельцев АЗС, промышленных и добывающих предприятий. На
              сегодняшний день ООО «РегионСнаб» является крупнейшим
              «независимым» поставщиком нефтепродуктов в Красноярском крае.
              <br /> <br />
              Огромный опыт, профессиональный коллектив и ресурсы компании
              позволяют быть уверенными в стабильности и надежности оказываемых
              услуг, а также осуществлять дальнейшее развитие компании.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutIntro;
